import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { GoToTop } from "../Fixed/GotoTop";

const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
      <GoToTop/>
    </div>
  );
};

export default Layout;
