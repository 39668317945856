import React from "react";

const Promotion = () => {
  return (
    <div>
      <section
        id="ambition_recent_work_widget-5"
        class="widget widget_recent_work clearfix"
      >
        {" "}
        <div class="container clearfix">
          <h2 class="widget-title">JILIKO PROMOTION</h2> <p></p>
        </div>
        <div class="recent-work-col">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            title="free!register now get free 100 bonus"
          >
            <img
              width="670"
              height="420"
              src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/register-now-get-free-100-bonus.png?resize=670%2C420&amp;ssl=1"
              class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
              alt="register now get free 100 bonus"
              decoding="async"
              fetchpriority="high"
              data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/register-now-get-free-100-bonus.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/register-now-get-free-100-bonus.png?resize=300%2C188&amp;ssl=1 300w"
              data-lazy-sizes="(max-width: 670px) 100vw, 670px"
              title="free!register now get free 100 bonus 1"
              data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/register-now-get-free-100-bonus.png?resize=670%2C420&amp;ssl=1"
              data-ll-status="loaded"
              sizes="(max-width: 670px) 100vw, 670px"
              srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/register-now-get-free-100-bonus.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/register-now-get-free-100-bonus.png?resize=300%2C188&amp;ssl=1 300w"
            />

            <span class="recent-work-content">
              <span class="recent-work-title">
                free!register now get free 100 bonus
              </span>
              free!register now get free 100 bonus{" "}
            </span>
          </a>
        </div>
        <div class="recent-work-col">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            title="300% welcome BONUS"
          >
            <img
              width="670"
              height="420"
              src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/300-welcome-BONUS.png?resize=670%2C420&amp;ssl=1"
              class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
              alt="300% welcome BONUS"
              decoding="async"
              data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/300-welcome-BONUS.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/300-welcome-BONUS.png?resize=300%2C188&amp;ssl=1 300w"
              data-lazy-sizes="(max-width: 670px) 100vw, 670px"
              title="300% welcome BONUS 2"
              data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/300-welcome-BONUS.png?resize=670%2C420&amp;ssl=1"
              data-ll-status="loaded"
              sizes="(max-width: 670px) 100vw, 670px"
              srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/300-welcome-BONUS.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/300-welcome-BONUS.png?resize=300%2C188&amp;ssl=1 300w"
            />

            <span class="recent-work-content">
              <span class="recent-work-title">300% welcome BONUS</span>
            </span>
          </a>
        </div>
        <div class="recent-work-col">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            title="Monthly sharing FB GET bonus"
          >
            <img
              width="670"
              height="420"
              src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-sharing-FB-GET-bonus.png?resize=670%2C420&amp;ssl=1"
              class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
              alt="Monthly sharing FB GET bonus"
              decoding="async"
              data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-sharing-FB-GET-bonus.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-sharing-FB-GET-bonus.png?resize=300%2C188&amp;ssl=1 300w"
              data-lazy-sizes="(max-width: 670px) 100vw, 670px"
              title="Monthly sharing FB GET bonus 3"
              data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-sharing-FB-GET-bonus.png?resize=670%2C420&amp;ssl=1"
              data-ll-status="loaded"
              sizes="(max-width: 670px) 100vw, 670px"
              srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-sharing-FB-GET-bonus.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-sharing-FB-GET-bonus.png?resize=300%2C188&amp;ssl=1 300w"
            />

            <span class="recent-work-content">
              <span class="recent-work-title">
                Monthly sharing FB GET bonus
              </span>
            </span>
          </a>
        </div>
        <div class="recent-work-col">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            title="Lucky draw, 100% Guaranteed Win 28~1688 bonus"
          >
            <img
              width="670"
              height="420"
              src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Lucky-draw.png?resize=670%2C420&amp;ssl=1"
              class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
              alt="Lucky draw"
              decoding="async"
              data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Lucky-draw.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Lucky-draw.png?resize=300%2C188&amp;ssl=1 300w"
              data-lazy-sizes="(max-width: 670px) 100vw, 670px"
              title="Lucky draw, 100% Guaranteed Win 28~1688 bonus 4"
              data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Lucky-draw.png?resize=670%2C420&amp;ssl=1"
              data-ll-status="loaded"
              sizes="(max-width: 670px) 100vw, 670px"
              srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Lucky-draw.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Lucky-draw.png?resize=300%2C188&amp;ssl=1 300w"
            />

            <span class="recent-work-content">
              <span class="recent-work-title">
                Lucky draw, 100% Guaranteed Win 28~1688 bonus
              </span>
            </span>
          </a>
        </div>
        <div class="recent-work-col">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            title="Daily Task GET BONUS"
          >
            <img
              width="670"
              height="420"
              src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Daily-Task.png?resize=670%2C420&amp;ssl=1"
              class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
              alt="Daily Task"
              decoding="async"
              data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Daily-Task.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Daily-Task.png?resize=300%2C188&amp;ssl=1 300w"
              data-lazy-sizes="(max-width: 670px) 100vw, 670px"
              title="Daily Task GET BONUS 5"
              data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Daily-Task.png?resize=670%2C420&amp;ssl=1"
              data-ll-status="loaded"
              sizes="(max-width: 670px) 100vw, 670px"
              srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Daily-Task.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Daily-Task.png?resize=300%2C188&amp;ssl=1 300w"
            />

            <span class="recent-work-content">
              <span class="recent-work-title">Daily Task GET BONUS</span>
            </span>
          </a>
        </div>
        <div class="recent-work-col">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            title="Monthly iPhone award Extra car big prize for X’mas"
          >
            <img
              width="670"
              height="420"
              src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-iPhone.png?resize=670%2C420&amp;ssl=1"
              class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
              alt="Monthly iPhone"
              decoding="async"
              data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-iPhone.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-iPhone.png?resize=300%2C188&amp;ssl=1 300w"
              data-lazy-sizes="(max-width: 670px) 100vw, 670px"
              title="Monthly iPhone award Extra car big prize for X'mas 6"
              data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-iPhone.png?resize=670%2C420&amp;ssl=1"
              data-ll-status="loaded"
              sizes="(max-width: 670px) 100vw, 670px"
              srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-iPhone.png?w=670&amp;ssl=1 670w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Monthly-iPhone.png?resize=300%2C188&amp;ssl=1 300w"
            />

            <span class="recent-work-content">
              <span class="recent-work-title">
                Monthly iPhone award Extra car big prize for X’mas
              </span>
            </span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Promotion;
