import React from "react";
import { BiLogoFacebook } from "react-icons/bi";
import { RiShareBoxLine } from "react-icons/ri";
// import { AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";
const Footer = () => {
  return (
    <div className="footer-wrapper pb-5 text-black">
      <div className="container">
        <div className="col">
          <div class="copyright">
            Copyright ©2023{" "}
            <a
              href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              title="JILIKO best JILI game casino"
            >
              <span>JILIKO best JILI game casino</span>
            </a>
          </div>
        </div>
        <div className="col">
          <div className="d-flex justify-content-end ">
            <a
              href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              target="_blank"
              className="text-white me-1"
              rel="noopener noreferrer"
            >
              <BiLogoFacebook className="icon-social" />
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              className="text-white"
            >
              <RiShareBoxLine className="icon-social" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
