import React from "react";

const Others = () => {
  return (
    <div>
      <section id="ambition_service_widget-3" class="widget widget_service">
        {" "}
        <div class="container clearfix">
          <div class="column  large-view clearfix">
            <div class="one-third">
              <div class="service-img">
                <a
                  title="Generous sign-up bonus"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  <img
                    width="210"
                    height="210"
                    src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?resize=210%2C210&amp;ssl=1"
                    class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
                    alt="Generous sign-up bonus"
                    decoding="async"
                    data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?resize=150%2C150&amp;ssl=1 150w"
                    data-lazy-sizes="(max-width: 210px) 100vw, 210px"
                    title="Generous sign-up bonus 7"
                    data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?resize=210%2C210&amp;ssl=1"
                    data-ll-status="loaded"
                    sizes="(max-width: 210px) 100vw, 210px"
                    srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?resize=150%2C150&amp;ssl=1 150w"
                  />
                  <noscript>
                    <img
                      width="210"
                      height="210"
                      src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?resize=210%2C210&amp;ssl=1"
                      class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image"
                      alt="Generous sign-up bonus"
                      decoding="async"
                      srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Generous-sign-up-bonus.png?resize=150%2C150&amp;ssl=1 150w"
                      sizes="(max-width: 210px) 100vw, 210px"
                      title="Generous sign-up bonus 7"
                    />
                  </noscript>{" "}
                </a>
              </div>
              <h3 class="service-title">
                <a
                  title="Generous sign-up bonus"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  Generous sign-up bonus
                </a>
              </h3>
              <article>
                <p>
                  Just register JILIKO to get many free bonuses, let you win big
                  every day{" "}
                </p>
              </article>
            </div>
            <div class="one-third">
              <div class="service-img">
                <a
                  title="Multiple game choices"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  {" "}
                  <img
                    width="210"
                    height="210"
                    src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?resize=210%2C210&amp;ssl=1"
                    class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
                    alt="Multiple game choices"
                    decoding="async"
                    data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?resize=150%2C150&amp;ssl=1 150w"
                    data-lazy-sizes="(max-width: 210px) 100vw, 210px"
                    title="Multiple game choices 8"
                    data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?resize=210%2C210&amp;ssl=1"
                    data-ll-status="loaded"
                    sizes="(max-width: 210px) 100vw, 210px"
                    srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?resize=150%2C150&amp;ssl=1 150w"
                  />
                  <noscript>
                    <img
                      width="210"
                      height="210"
                      src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?resize=210%2C210&amp;ssl=1"
                      class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image"
                      alt="Multiple game choices"
                      decoding="async"
                      srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Multiple-game-choices.png?resize=150%2C150&amp;ssl=1 150w"
                      sizes="(max-width: 210px) 100vw, 210px"
                      title="Multiple game choices 8"
                    />
                  </noscript>{" "}
                </a>
              </div>
              <h3 class="service-title">
                <a
                  title="Multiple game choices"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  Multiple game choices
                </a>
              </h3>
              <article>
                <p>
                  JILIKO has the most easy-to-win games on various Philippine
                  casino sites,{" "}
                </p>
              </article>
            </div>
            <div class="one-third clearfix-half">
              <div class="service-img">
                <a title="No Proxy Direct Website" href="#/">
                  {" "}
                  <img
                    width="210"
                    height="210"
                    src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?resize=210%2C210&amp;ssl=1"
                    class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
                    alt="No Proxy Direct Website"
                    decoding="async"
                    data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?resize=150%2C150&amp;ssl=1 150w"
                    data-lazy-sizes="(max-width: 210px) 100vw, 210px"
                    title="No Proxy Direct Website 9"
                    data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?resize=210%2C210&amp;ssl=1"
                    data-ll-status="loaded"
                    sizes="(max-width: 210px) 100vw, 210px"
                    srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?resize=150%2C150&amp;ssl=1 150w"
                  />
                  <noscript>
                    <img
                      width="210"
                      height="210"
                      src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?resize=210%2C210&amp;ssl=1"
                      class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image"
                      alt="No Proxy Direct Website"
                      decoding="async"
                      srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/No-Proxy-Direct-Website.png?resize=150%2C150&amp;ssl=1 150w"
                      sizes="(max-width: 210px) 100vw, 210px"
                      title="No Proxy Direct Website 9"
                    />
                  </noscript>{" "}
                </a>
              </div>
              <h3 class="service-title">
                <a
                  title="No Proxy Direct Website"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  No Proxy Direct Website
                </a>
              </h3>
              <article>
                <p>
                  jiliko.cc official website direct sales, no need to go through
                  an agent, no commission, let you win the highest bonus{" "}
                </p>
              </article>
            </div>
            <div class="one-third clearfix-third">
              <div class="service-img">
                <a
                  title="Professional encryption website"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  {" "}
                  <img
                    width="210"
                    height="210"
                    src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?resize=210%2C210&amp;ssl=1"
                    class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
                    alt="Professional encryption website"
                    decoding="async"
                    data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?resize=150%2C150&amp;ssl=1 150w"
                    data-lazy-sizes="(max-width: 210px) 100vw, 210px"
                    title="Professional encryption website 10"
                    data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?resize=210%2C210&amp;ssl=1"
                    data-ll-status="loaded"
                    sizes="(max-width: 210px) 100vw, 210px"
                    srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?resize=150%2C150&amp;ssl=1 150w"
                  />
                  <noscript>
                    <img
                      width="210"
                      height="210"
                      src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?resize=210%2C210&amp;ssl=1"
                      class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image"
                      alt="Professional encryption website"
                      decoding="async"
                      srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Professional-encryption-website.png?resize=150%2C150&amp;ssl=1 150w"
                      sizes="(max-width: 210px) 100vw, 210px"
                      title="Professional encryption website 10"
                    />
                  </noscript>{" "}
                </a>
              </div>
              <h3 class="service-title">
                <a
                  title="Professional encryption website"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  Professional encryption website
                </a>
              </h3>
              <article>
                <p>
                  jiliko adopts professional encryption services to protect
                  every penny of your funds{" "}
                </p>
              </article>
            </div>
            <div class="one-third clearfix-half">
              <div class="service-img">
                <a
                  title="Simple website interface"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  {" "}
                  <img
                    width="210"
                    height="210"
                    src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?resize=210%2C210&amp;ssl=1"
                    class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
                    alt="Simple website interface"
                    decoding="async"
                    data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?resize=150%2C150&amp;ssl=1 150w"
                    data-lazy-sizes="(max-width: 210px) 100vw, 210px"
                    title="Simple website interface 11"
                    data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?resize=210%2C210&amp;ssl=1"
                    data-ll-status="loaded"
                    sizes="(max-width: 210px) 100vw, 210px"
                    srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?resize=150%2C150&amp;ssl=1 150w"
                  />
                  <noscript>
                    <img
                      width="210"
                      height="210"
                      src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?resize=210%2C210&amp;ssl=1"
                      class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image"
                      alt="Simple website interface"
                      decoding="async"
                      srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Simple-website-interface.png?resize=150%2C150&amp;ssl=1 150w"
                      sizes="(max-width: 210px) 100vw, 210px"
                      title="Simple website interface 11"
                    />
                  </noscript>{" "}
                </a>
              </div>
              <h3 class="service-title">
                <a
                  title="Simple website interface"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  Simple website interface
                </a>
              </h3>
              <article>
                <p>
                  jiliko has a simple and uncomplicated website interface,
                  making it very easy for you to register or bet on games{" "}
                </p>
              </article>
            </div>
            <div class="one-third">
              <div class="service-img">
                <a
                  title="Local Filipino Customer Service"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  {" "}
                  <img
                    width="210"
                    height="210"
                    src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?resize=210%2C210&amp;ssl=1"
                    class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image entered lazyloaded"
                    alt="Local Filipino Customer Service"
                    decoding="async"
                    data-lazy-srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?resize=150%2C150&amp;ssl=1 150w"
                    data-lazy-sizes="(max-width: 210px) 100vw, 210px"
                    title="Local Filipino Customer Service 12"
                    data-lazy-src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?resize=210%2C210&amp;ssl=1"
                    data-ll-status="loaded"
                    sizes="(max-width: 210px) 100vw, 210px"
                    srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?resize=150%2C150&amp;ssl=1 150w"
                  />
                  <noscript>
                    <img
                      width="210"
                      height="210"
                      src="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?resize=210%2C210&amp;ssl=1"
                      class="attachment-ambition-recent-work size-ambition-recent-work wp-post-image"
                      alt="Local Filipino Customer Service"
                      decoding="async"
                      srcset="https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?w=210&amp;ssl=1 210w, https://i0.wp.com/jiliko.cc/wp-content/uploads/2023/08/Local-Filipino-Customer-Service.png?resize=150%2C150&amp;ssl=1 150w"
                      sizes="(max-width: 210px) 100vw, 210px"
                      title="Local Filipino Customer Service 12"
                    />
                  </noscript>{" "}
                </a>
              </div>
              <h3 class="service-title">
                <a
                  title="Local Filipino Customer Service"
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                  className="text-decoration-none text-black"
                >
                  Local Filipino Customer Service
                </a>
              </h3>
              <article>
                <p>
                  jiliko.cc is a local company in the Philippines, not a foreign
                  company, so you can bet with peace of mind.…{" "}
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Others;
