import React from "react";

const Hero = () => {
  return (
    <section className="home-hero d-flex justify-content-center align-items-center">
      <div class="container clearfix ">
        <article class="featured-text">
          <h2 class="featured-title">
            <a
              href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              title="Download APP GET 100 bonus"
              rel="bookmark"
              className="text-decoration-none"
            >
              Download APP GET 100 bonus
            </a>
          </h2>
          {/* <!-- .featured-title --> */}
          <div class="featured-content">
            At jiliko, we have numerous bonuses for players, whether you are a
            new member or a returning member, so you can take advantage of our
            best promotions and use them to take your gaming experience to the
            next level. Join jiliko today to receive the best bonuses like Jili
            Free 100!{" "}
          </div>
          {/* <!-- .featured-content --> */}
          <a
            title="JILIKO LOG IN"
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            class="call-to-action active text-decoration-none border-0"
            target="_blank"
            rel="noopener noreferrer"
          >
            JILIKO LOG IN
          </a>
          <a
            title="More JILI Free 100 php"
            href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
            class="call-to-action text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
          >
            More JILI Free 100 php
          </a>
        </article>
        {/* <!-- .featured-text --> */}
      </div>
    </section>
  );
};

export default Hero;
