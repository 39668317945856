import React from "react";

const Download = () => {
  return (
    <div>
      <section
        id="ambition_promobox_widget-5"
        class="widget widget_promotional_bar"
      >
        <div
          data-bg="https://jiliko.cc/wp-content/uploads/2023/06/06-05-143314.png"
          class="promotional_bar_content rocket-lazyload entered lazyloaded"
          data-ll-status="loaded"
        >
          <div class="container clearfix">
            <h2 class="widget-title">jiliko app 2.0 download </h2>

            <p class="highlight-content">
              Download the JILIko app 2.0 and log in to get 100 php{" "}
            </p>
            <a
              class="call-to-action"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              title="Sign up now"
            >
              Sign up now
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Download;
