import React, { useState, useEffect } from "react";

export const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!isVisible && window.pageYOffset > 100) {
        setIsVisible(true);
      } else if (isVisible && window.pageYOffset <= 100) {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);

    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [isVisible]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`btn-to-top absolute rounded-top ${
        isVisible ? "visible" : "hidden"
      }`}
      onClick={scrollToTop}
    >
      <span className="btn rounded-circle rounded-circles">
        <i className="bi bi-chevron-up text-white"></i>
      </span>
    </div>
  );
};
