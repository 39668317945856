import { useState } from "react";
import { BiLogoFacebook } from "react-icons/bi";
import { RiShareBoxLine } from "react-icons/ri";
import { AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";
import { navMenu } from "../../Data/NavMenu";
const Header = () => {
  const [showPopover, setShowPopover] = useState(false);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  return (
    <>
      <div className="header-container">
        <div className="container py-1">
          <div className="d-flex justify-content-end ">
            <a
              href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              target="_blank"
              className="text-white me-1"
              rel="noopener noreferrer"
            >
              <BiLogoFacebook className="icon-social" />
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              className="text-white"
            >
              <RiShareBoxLine className="icon-social" />
            </a>
          </div>
        </div>
        <div className="border-header"></div>
        <div className="container">
          <nav class="navbar navbar-expand-lg text-white ">
            <div class="container">
              <a
                class="navbar-brand text-white"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
              >
                <img
                  src="https://jiliko.cc/wp-content/uploads/2023/05/JILIKO-LOGO.png"
                  alt="logo"
                  srcset=""
                />
              </a>

              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <AiOutlineMenu className="text-white" />
              </button>

              <div
                class="collapse navbar-collapse justify-content-lg-end"
                id="navbarText"
              >
                <span class="navbar-text text-white d-flex justify-content-end ">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <li class="nav-item">
                      <a
                        className="nav-link active text-uppercase"
                        aria-current="page"
                        href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                      >
                        Home
                      </a>
                    </li>
                  </ul>
                  {navMenu.map((item) => (
                    <ul key={item} class="navbar-nav me-auto mb-2 mb-lg-0 ">
                      <li class="nav-item">
                        <a
                          className="nav-link"
                          aria-current="page"
                          href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                        >
                          {item}
                        </a>
                      </li>
                    </ul>
                  ))}
                </span>
                <button
                  type="button"
                  className="btn btn"
                  onClick={togglePopover}
                  data-bs-toggle="popover"
                  data-bs-placement="bottom"
                >
                  <AiOutlineSearch className="icon-search" />
                </button>
                {showPopover && (
                  <div className="popover popover-bottom">
                    <div className="popover-arrow"></div>
                    <div className="popover-body d-flex">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control"
                      />
                      <button
                        type="button"
                        className="btn btn-secondary search-btn rounded-0"
                      >
                        SEARCH
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
