import React from "react";
import Hero from "../components/Home/Hero";
import About from "../components/Home/About";
import Promotion from "../components/Home/Promotion";
import Others from "../components/Home/Others";
import Download from "../components/Home/Download";

const Home = () => {
  return (
    <>
      <main>
        <div className="main-body">
          <Hero />
        </div>
        <About />
        <Promotion/>
        <Others/>
        <Download/>
      
      </main>
    </>
  );
};

export default Home;
