import React from "react";

const About = () => {
  return (
    <section className="widget_featured_page">
      <div className="container containers clearfox">
        <div className="row">
          <div className="col">
            <div class="featured-page-content">
              <h2 class="widget-title">
                WELCOME&nbsp;JAYSON CASTRO &amp; ARWIND SANTOS&nbsp;&nbsp;join
                jiliko free 100
              </h2>
              <p className="text-muted">
                With advanced game analysis technology, jili free 100 php Games
                provides safe and reliable game experience for players. jiliko
                bet ph has accumulated rich experience in virtual game
                development. It owns the seasoned R&amp;D team with corporation
                culture to avoid copycat games. With the outstanding video
                production team, we are continuously innovating with new games,
                so stay tuned with us if you want to get updates on our newest
                casino games.
              </p>
              <a
                title="WELCOME&nbsp;JAYSON CASTRO &amp; ARWIND SANTOS&nbsp;&nbsp;join jiliko free 100"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-official"
                class="call-to-action text-decoration-none"
              >
                Read more
              </a>
            </div>
          </div>
          <div className="col">
            <div class="featured-page-image">
              <img
                alt="WELCOME&nbsp;JAYSON CASTRO &amp; ARWIND SANTOS&nbsp;&nbsp;join jiliko free 100"
                src="https://jiliko.cc/wp-content/uploads/2023/08/01.png"
                data-lazy-src="https://jiliko.cc/wp-content/uploads/2023/08/01.png"
                data-ll-status="loaded"
                class="entered lazyloaded"
              />
              {/* <img
                alt="WELCOME JAYSON CASTRO &amp; ARWIND SANTOS  join jiliko free 100"
                src="https://jiliko.cc/wp-content/uploads/2023/08/01.png"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
